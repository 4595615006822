import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './js/App';
import { App3 } from './js/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <App3 /> 
  
);



